import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import { OG_PAGE_TYPES } from 'common/Seo/constants';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import CampaignLandingBanner from 'components/CampaignLandingBanner';
import { ArticlePromoListing } from 'components/Listing';
import PageDescription from 'components/PageDescription';
import SecondaryNavigation from 'components/SecondaryNavigation';
import WithBackgroundColor from 'components/WithBackgroundColor';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { ArticlePageProps } from './models';

import './ArticlePage.scss';

const ArticlePage: FC<{
  data: ArticlePageProps;
  pageContext: PageContent.PageContext;
  location: PageContent.LocationType;
}> = ({
  data: {
    articlePage: {
      seo,
      langProps,
      pageDescription,
      body,
      banner: [banner],
      alternativeBanner,
      articleListing: [articleListing],
      secondaryNavigationType,
      secondaryNavigationLabel,
      articleType,
    },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);
  const secondaryNavigationAnchors = adaptedBody.map(
    ({ value }) => value.secondaryNavigationAnchor
  );
  const articleImageUrl =
    banner?.image?.imageCropper?.desktop?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;

  const wrapperClassName = `article-page--${articleType?.[0]}`;

  return (
    <Layout
      {...{
        seo: { ...seo, seoOGType: seo.seoOGType || OG_PAGE_TYPES.ARTICLE },
        breadcrumbs,
        langProps,
        pagePathname,
        additionalSchema: 'Article',
        schemaImageUrl: articleImageUrl,
        className: wrapperClassName,
      }}
    >
      {banner ? <Banner {...banner} mode={BannerMode.article} /> : null}
      {alternativeBanner?.length ? <CampaignLandingBanner {...alternativeBanner[0]} /> : null}
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation
          anchors={secondaryNavigationAnchors}
          lang={langProps.lang}
          secondaryNavigationType={secondaryNavigationType?.[0]}
          secondaryNavigationLabel={secondaryNavigationLabel}
        />
      ) : null}

      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      {adaptedBody.map((component) => {
        const key = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
      {articleListing ? (
        <WithBackgroundColor {...articleListing}>
          <ArticlePromoListing {...articleListing} />
        </WithBackgroundColor>
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    articlePage(link: { eq: $link }) {
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seo {
        ...SEOStructureFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      banner {
        ...BannerFragment
      }
      articleListing {
        ...ListingFragment
      }
      alternativeBanner {
        title
        alt
        cta {
          url
          name
        }
        ctaAriaLabel
        description
        image {
          ...ImageFragment
        }
      }
      body {
        ...ArticleSectionFragment
        iconsList {
          ...IconsListSectionFragment
        }
        articleCategoryListingListing {
          ...ListingFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
        shoppingOptions {
          ...ShoppingOptionsFragment
        }
        shoppingButtons {
          ...ShoppingButtonsFragment
        }
        productsListing {
          ...ListingFragment
          useExternalLinks
        }
        teaser {
          ...TeaserFragment
        }
        infoCardsListing {
          secondaryNavigationAnchor
          title
          backgroundColor
          cardsInRow
          ctaAriaLabel
          description
          displayMode
          showAllAriaLabel
          cta {
            name
            url
          }
          cards {
            description
            subDescription
            title
          }
        }
        donateBanner {
          secondaryNavigationAnchor
          alt
          description
          ctaAriaLabel
          title
          image {
            ...ImageFragment
          }
          cta {
            name
            url
          }
        }
        couponSection {
          image {
            ...BannerImageFragment
          }
          alt
          caption
          fileToDownload {
            fallbackUrl
          }
        }
        selectorBanner {
          secondaryNavigationAnchor
          alt
          description
          title
          options {
            title
            url
          }
          ctaTitle
          optionLabel
          imageIsIcon
          image {
            ...BannerImageFragment
          }
        }
        infoBlock {
          description
        }
        articlePromoListing {
          ...ListingFragment
        }
        articleCampaignListing {
          ...ListingFragment
        }
        linkListing {
          secondaryNavigationAnchor
          title
          backgroundColor
          cardsInRow
          ctaAriaLabel
          description
          displayMode
          showAllAriaLabel
          cta {
            name
            url
          }
          cards {
            ctaLabel
            url
            description
            subDescription
            isCardClickable
            title
            image {
              ...ImageFragment
            }
          }
        }
      }
      secondaryNavigationType
      secondaryNavigationLabel
      articleType
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ArticlePage;
